.logo {
    width: 270px;
    height: 60px;
}

.logo img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    overflow: hidden;
}

.search-section div {
    border: 1px solid rgb(231, 226, 226);
}

.search-category {
    border-right: none !important;
}

.searchButton {
    background-color: #F33333;
    cursor: pointer;
    color: #fff;
    font-weight: 500;
    border: none;
}

.searchButton .fa-search {
    color: #fff;
    margin-right: 5px;
}

.searchField input {
    outline: none;
    border: none;
}

.search-category {
    padding: 9px 15px;
}

.searchButton {
    padding: 10px 15px;
}

.search-category .fa {
    margin-left: 5px;
}

.icon .fa {
    font-size: 20px;
    margin-right: 3px;
}

.counter {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    color: #fff;
    font-size: 10px;
    background-color: #F33333;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cart-price {
    color: #F33333;
}

.sCat {
    border: none;
    outline: none;
}

.cart-info-wrapper {
    cursor: pointer;
    position: relative;
}

.cart-info-wrapper .cart-items-container {
    position: absolute;
    background: #fff;
    right: 0;
    top: 100%;
    visibility: hidden;
    opacity: 0;
    transition: all 0.4s;
    box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
    /* border-radius: 4px; */
    /* border: 1px solid rgba(0, 0, 0, .15); */
    /* float: left; */
    list-style: none;
    background-clip: padding-box;
    min-width: 350px;
    /* padding: 10px; */
    margin: 2px 0 0;
    z-index: 10;
}

.cart-info-wrapper:hover .cart-items-container {
    visibility: visible;
    opacity: 1;
}

.cart-info-wrapper h6.text-uppercase {
    color: inherit;
    transition: all 0.3s;
}

.cart-info-wrapper:hover h6.text-uppercase {
    color: #F33333;
}

.cart-items-container .table>tbody>tr>td {
    border: none;
    border-bottom: 1px solid rgb(223, 223, 223);
    vertical-align: middle;
}

.size-img-cart {
    width: 80px;
    max-width: 80px;
}

.cart-items-container .content-item {
    max-height: 245px;
    overflow: auto;
}

.img-thumb img {
    height: 60px;
    width: 50px;
    border: 1px solid rgb(202, 202, 202);
}

.cart-info-wrapper .cart-items-container ul {
    list-style-type: none;
    padding: 0;
}

.rmCart .fa-trash-o {
    /* font-size: 12px; */
}

.viewCartButton,
.checkoutButton {
    outline: none;
    border: none;
    border-radius: 4px;
    transition: all 0.3s;
    color: #fff;
    font-weight: 400;
    padding: 10px 15px;
    font-size: 16px;
}

.viewCartButton {
    background-color: rgb(56, 56, 56);
}

.viewCartButton:hover {
    background-color: #F33333;
}

.checkoutButton {
    background-color: #F33333;

}

.checkoutButton:hover {
    background-color: rgb(56, 56, 56);

}